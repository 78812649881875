<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-6"
      :label="$t('modulos.cargo_funcao.filtro.nome')"
      em-filtro
    />
    <input-text
      v-model="filtro.cbo"
      class="col-6"
      :label="$t('modulos.cargo_funcao.filtro.cbo')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
    };
  },
};
</script>

